<template>
  <b-modal
    id="accept-modal"
    ref="accept-modal"
    title="Xác nhận thông tin"
    size="xl"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
  >
    <b-overlay>
      <h4 style="text-align: center">Thông tin khách hàng tham gia</h4>
      <template-table
        :column="infoTable"
        :data="data"
        :searchAction="false"
        :pagingAction="false"
        :selectAction="false"
        :tableAction="false"
        class="mt-3"
        bordered
      >
        <template v-slot:body="{ item }">
          <td>{{ item.name }}</td>
          <td>{{ item.phoneNumber }}</td>
          <td>{{ item.packageName }}</td>
          <td>{{ item.packageCode }}</td>
        </template>
      </template-table>

      <!-- <b-container fluid class="p-0">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-row>
            <b-col>
              <basic-select :options="courses" name="course" label="Chọn nhóm tham gia có sẵn"
                :value="getNameOf('course', this.form.courseId)"
              
                placeholder="--- Chọn nhóm tham gia ---" track-by="value" value-label="text"
                :hideSelected="true" :clear="false" :solid="false" changeValueByObject 
                :disabled="true"/>
            </b-col>
          </b-row>
        </form>
      </b-container> -->
      <!-- <basic-select
        :options="courseId"
        track-by="id"
        value-label="text"
        :value.sync="courseId"
        v-model="courseId"
      /> -->
    </b-overlay>
    <template #modal-footer>
      <div class="w-100 d-flex align-items-center justify-content-end">
        <b-button
          class="btn btn-plain ml-2"
          href="#"
          @click.stop="handleCancel"
          tabindex="0"
        >
          Thoát
        </b-button>
        <b-button
          class="btn btn-success ml-2"
          href="#"
          @click.stop="handleSubmit"
          tabindex="0"
          :disabled="loading"
        >
          <span class="svg-icon">
            <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
          </span>
          Xác nhận
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('customerReceives');
export default {
  name: 'AcceptModal',
  props: {
    targetItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        id: null,
        packageName: null,
        phoneNumber: null,
        name: null,
        packageCode: null,
        courseId: null,
        packageId: null
      },
      // courseId: [
      //   { id: '350a3050-c0f7-11ef-b57a-03ea338ae610', text: 'ONBOARDING' },
      // ],
      loading: false,
      infoTable: [
        { label: 'Tên khách hàng', key: 'name' },
        { label: 'Số điện thoại', key: 'phoneNumber' },
        { label: 'Gói dịch vụ', key: 'packageName' },
        { label: 'Mã gói', key: 'packageCode' },
      ],
      data: [],
    };
  },
  methods: {
    resetModal() {
      this.form = {};
      this.initialFrom = {};
      this.isChange = false;
    },

    // getNameOf(key, value) {
    //   switch (key) {
    //     case 'course':
    //       return this.courses?.find((item) => item.value == value) ?? null;
    //   }
    // },
    // selected(value, form, optionsArr) {
    //   const v = value
    //     ? optionsArr.find((el) => el.value === value?.value)
    //     : null;
    //   switch (form) {
    //     case 'course':
    //       this.form.courseId = v.value;
    //       break;
    //   }
    // },

    async handleConfirm() {
      return await this.$swal({
        title: 'Xác nhận?',
        text: `Dữ liệu thay đổi chưa được lưu, xác nhận thoát?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Huỷ',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Xác nhận',
            value: true,
            visible: true,
            className: '',
            closeModal: true,
          },
        },
      });
    },

    async handleSubmit() {
      this.loading = true;
      var payload = {
        id: this.form.id,
        phoneNumber: this.form.phoneNumber,
        courseId: this.form.courseId,
        packageId: this.form.packageId,
      };

      try {
        await this.$api.put(
          `/CustomerReceives/accept/${this.form.id}`,
          payload,
          {
            headers: { 'Content-Type': 'application/json' },
          },
        );
        this.$toastr.s({
          title: 'Thành công !',
          msg: 'Cập nhật thành công',
        });
        this.loading = false;
      } catch (error) {
        if (error) {
          return this.$toastr.e({
            title: 'Lỗi !',
            msg: error.message,
          });
        }
      } finally {
        this.$emit('load-data');
        this.$bvModal.hide('accept-modal');
      }
    },

    async handleCancel() {
      if (this.isChange) {
        let confirm = await this.handleConfirm();

        confirm == true ? this.$bvModal.hide('accept-modal') : null;
      } else {
        this.$bvModal.hide('accept-modal');
      }
    },
  },
  computed: {
    ...mapState(['courses']),
  },
  watch: {
    targetItem: {
      deep: true,
      handler(val) {
        this.form = {
          id: val.id,
          phoneNumber: val.phoneNumber,
          name: val.name,
          packageName: val.packageName,
          packageCode: val.packageCode,
          courseId: val.courseId,
          packageId: val.packageId,
        };
        this.data = [val];
      },
    },
  },
  mounted() {
    //   this.form.courseId = '350a3050-c0f7-11ef-b57a-03ea338ae610';
  },
};
</script>
